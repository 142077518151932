.content {
  width: 100%;
  margin-bottom: 0;
  padding: 0 24px;
}

.Documentation__container * {
  box-sizing: border-box;
}
.Documentation__container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;

  width: 100%;
  height: 100%;

  margin-bottom: 24px;
}

.Documentation__container--mobile {
  flex-flow: column nowrap;
  z-index: 9000;
}

.Documentation__container--mobile > .Menu__container {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;

  overflow-y: auto;
  flex-flow: column nowrap;

  max-width: 300px;
  min-width: 240px;
  width: 70%;
  padding: 16px 0 16px 24px;
  align-items: flex-end;

  transition: transform 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s,
    opacity 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transform: translateX(-300px);
}
.Documentation__container--mobile > .Menu__container.Menu__container--shown {
  transform: translateX(0);
  pointer-events: fill;
  opacity: 1;
}

.Scrim {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: rgba(0, 0, 0, 0.57);
  transition: opacity 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  opacity: 0;
  pointer-events: none;
}
.Scrim--shown {
  cursor: pointer;
  pointer-events: fill;
  overflow: hidden;
  opacity: 1;
}

.Menu__container {
  display: flex;
  flex-flow: column wrap;
  flex: 0 0 245px;
  justify-content: flex-start;
  align-items: flex-start;

  height: 100%;

  padding: 24px 0 24px 0;
}

.Menu__container .Menu__category {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  min-height: 36px;
  width: 100%;

  margin: 0;

  color: rgba(0, 0, 0, 0.47);
  font-weight: bold;
}

.Menu__container .Menu__page {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  min-height: 36px;
  height: 36px;
  width: 100%;

  margin: 0;
  padding: 8px 16px;

  border-bottom-left-radius: calc(36px / 2);
  border-top-left-radius: calc(36px / 2);
  border-right: 4px solid transparent;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  font-size: 14px;
  text-align: left;
  text-decoration: none;
  transition: color 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s,
    background-color 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s,
    border-color 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  outline: none;
}
.Menu__container .Menu__page:hover {
  background-color: rgba(31, 130, 228, 0.1);
  color: rgba(31, 130, 228, 1);
  outline: none;
  text-decoration: none;
}
.Menu__container .Menu__page:focus {
  outline: "none";
}

.Menu__container .Menu__page.Menu__page--active {
  border-right: 4px solid rgba(31, 130, 228, 1);
  color: rgba(31, 130, 228, 1);
  font-weight: bold;
}

.Content__container,
.Content__container--empty {
  flex: 1;
  height: 100%;
  padding: 24px;
  height: 100%;

  background-color: white;
  text-align: left;
}

.Content__container {
  height: 100%;
  max-width: 100%;

  margin-bottom: 24px;

  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px;
  border-radius: 2px;
}
.Content__container--empty {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;

  background-color: transparent;
}

.Content__container p {
  line-height: 26px;
}
.Content__container img {
  max-width: 100% !important;
  height: auto !important;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px;
  border-radius: 2px;
  margin: 0 auto;
}
.Content__container .block-img {
  max-width: 100% !important;
  height: auto !important;
  margin: 36px 0;
  text-align: center;
}

.Content__container *:first-child {
  margin-top: 0 !important;
}
.Content__container *:last-child {
  margin-bottom: 0 !important;
}

.Content__container > h1 {
  font-size: 24px;
}

.Content__container > div {
  font-size: 16px;
}
.Content__container > div > h5 {
  font-size: 14px;
}
.Content__container > div > h5 > em,
.Content__container > div > h6 > em {
  font-weight: normal;
}

.Content__title {
  padding: 0 24px 16px;
  margin: 0 -24px 16px -24px;

  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

pre {
  overflow: auto;
  padding: 16px;
  max-width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #fcfcfc;
}

ul {
  margin: 16px 12px;
  padding: 0 12px;
}

ul > li {
  margin-bottom: 8px;
}

ul > li:last-child {
  margin-bottom: 0;
}
