.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  border-radius: calc(46px / 2);
  padding: 0px 20px;
  margin: 12px;
  border: none;
  background-color: white;
  color: #1f82e4;
  letter-spacing: 0.06em;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  will-change: box-shadow;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: background-color 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s,
    color 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s,
    box-shadow 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s,
    opacity 560ms cubic-bezier(0.25, 0.8, 0.25, 1) 1s;
}

.button:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
    0 10px 10px rgba(248, 232, 232, 0.22);
  background-color: #1f82e4;
  color: white !important;
  text-decoration: none !important;
  outline: none;
}

.button:active {
  background-color: #1b70c5;
  color: white;
  outline: none;
}

.button.primary {
  background-color: #1f82e4;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}
.button.primary:hover {
  background-color: white;
  color: #1b70c5 !important;
}
