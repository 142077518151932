.Landing__hero {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  margin: 32px 0;
}

.Features__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: row wrap;

  max-width: 1160px;

  padding: 0;
  margin: 32px -60px 32px;
}
.Features__container.Features__container--mobile {
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;

  max-width: 100%;
  width: 100%;
  margin: 0 24px 24px;
}

.Screenshots__container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  align-self: center;
  flex: 1;

  max-height: 400px;
  min-height: 200px;
  height: 400px;
  width: 100%;
  max-height: 380px;
  max-width: 960px;

  padding: 0;
  margin: 32px 0 0 0;
  overflow: hidden;
  border-radius: 1px;
}
.Screenshots__container--mobile {
  height: 225px;
}
