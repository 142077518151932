.screenshot {
  height: 80%;
  width: 30%;
  margin: 0 -8px -36px 0;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: #fbfbfb;
  transition: transform 140ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s,
    box-shadow 140ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  will-change: box-shadow;
  user-select: none;
}
.screenshot:hover {
  transform: translateY(-16px);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  z-index: 8000;
}
.screenshotUpper {
  height: 100%;
  width: 33%;
  z-index: 5000;
  margin-bottom: -20px;
}
.screenshotImage {
  width: 100%;
  height: auto;
  border-radius: 4px;
}
