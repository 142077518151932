.feature {
  flex: 1;
  padding: 0 24px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}

.feature--icon {
  margin: 20px 0 16px;
  width: 48px;
  height: 48px;
}

.feature--name {
  font-size: 22px;
  font-weight: lighter;
  color: rgba(8, 10, 32, 0.87);
  margin-bottom: 12px;
  line-height: 1.8em;
}

.feature--description {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.67);
  line-height: 1.5em;
  text-align: center;
}
