.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  height: 100%;
  max-width: 1300px;
  width: 100%;
  text-align: center;
  padding: 0;
  overflow: hidden;
}
.container__full-height {
  height: 100vh;
}

.wrapper {
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  flex-flow: column;
  flex: 1;
  width: 100%;
}

.content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  flex: 1;
}
.content a {
  font-weight: 500;
  text-decoration: none;
  color: #1f82e4;
  cursor: pointer;
  transition: color 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  outline: none;
}
.content a:hover {
  color: rgb(22, 99, 194);
  outline: none;
  text-decoration: underline;
}
.content a:active {
  outline: none;
  color: rgba(31, 130, 228, 1);
}

.content__full-height {
  flex: 1;
  height: 100%;
  margin-bottom: 180px;
}
.Heading {
  font-size: 42px;
  font-family: "Pridi", serif;
  font-weight: bold;
  margin: 16px 0 0 0;
  text-align: center;
  letter-spacing: 1.5px;
  line-height: 46px;
  color: #1b70c5;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.Tagline {
  font-size: 18px;
  font-weight: lighter;
  color: rgba(0, 0, 0, 0.67);
  max-width: 700px;
  line-height: 26px;
  margin-bottom: 24px;
}
.Heading.Heading--mobile {
  margin: 0;
  font-size: 32px;
}
.Tagline.Tagline--mobile {
  font-size: 16px;
}

.Tagline.Tagline--mobile,
.Heading.Heading--mobile {
  padding: 0;
  width: 100%;
  max-width: 100%;
}
.buttonsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  margin: 16px 0 24px;
}

.config-issues {
  font-weight: normal;
  font-style: italic;
  font-size: 12px;
  white-space: pre-line;
  line-height: 1.5em;
}

.Footer__container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 30px !important;
  padding: 16px 24px 12px 24px;
  background-color: #ffffff;
  margin: 0 -24px;
  border-top: 2px solid #e7e7e7;
  box-sizing: border-box;
  font-size: 10px;
}
.Footer__container a {
  font-weight: 500;
  text-decoration: none;
  color: #1f82e4;
  padding: 4px 8px;
  margin: 0px 0 0px 8px;
  cursor: pointer;
  border-radius: 24px;
  transition: color 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s,
    background-color 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  outline: none;
}
.Footer__container a:hover {
  color: rgba(31, 130, 228, 1);
  background-color: rgba(31, 130, 228, 0.1);
  outline: none;
}
.Footer__container a:active {
  outline: none;
  color: rgba(31, 130, 228, 1);
  background-color: rgba(31, 130, 228, 0.2);
}
.Footer__container a.active {
  color: #1f82e4;
}
