.Logo {
  /* width: 360px; */
  min-height: 60px;
  max-height: 60px;
  cursor: pointer;
}

.Logo--small {
  width: 200px;
  height: 45px;
}
