@import url(https://fonts.googleapis.com/css?family=Open+Sans|Pridi);
.spinner {
  -webkit-animation: rotation 1.35s linear infinite;
  animation: rotation 1.35s linear infinite;
}

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

.circle {
  stroke-dasharray: 180;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: turn 1.35s ease-in-out infinite;
  animation: turn 1.35s ease-in-out infinite;
}

@-webkit-keyframes turn {
  0% {
    stroke-dashoffset: 180;
  }

  50% {
    stroke-dashoffset: 45;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 180;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

@keyframes turn {
  0% {
    stroke-dashoffset: 180;
  }

  50% {
    stroke-dashoffset: 45;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 180;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

.Logo {
  /* width: 360px; */
  min-height: 60px;
  max-height: 60px;
  cursor: pointer;
}

.Logo--small {
  width: 200px;
  height: 45px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  border-radius: calc(46px / 2);
  padding: 0px 20px;
  margin: 12px;
  border: none;
  background-color: white;
  color: #1f82e4;
  letter-spacing: 0.06em;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  will-change: box-shadow;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: background-color 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s,
    color 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s,
    box-shadow 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s,
    opacity 560ms cubic-bezier(0.25, 0.8, 0.25, 1) 1s;
}

.button:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
    0 10px 10px rgba(248, 232, 232, 0.22);
  background-color: #1f82e4;
  color: white !important;
  text-decoration: none !important;
  outline: none;
}

.button:active {
  background-color: #1b70c5;
  color: white;
  outline: none;
}

.button.primary {
  background-color: #1f82e4;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}
.button.primary:hover {
  background-color: white;
  color: #1b70c5 !important;
}

.Header {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  min-height: 56px;

  padding: 16px 24px;
  background-color: #ffffff;
  margin: 0 -24px 24px -24px;
  border-bottom: 2px solid #e7e7e7;
}
.Header--mobile {
  align-items: center;
  border-bottom: none;
  padding: 0;
  flex-direction: row-reverse;
}

.mobileMenuWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.logo {
  flex: 0.2 1;
  justify-self: flex-start;
  cursor: pointer;
}

.Logo__wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.Logo__wrapper--mobile {
  height: 50px;
  width: 100%;
  margin: 0;
  padding: 8px 8px;
  background-color: #fff;
  justify-content: space-between;
  z-index: 2000;
}

.Logo {
  width: 190px;
  height: 43.3px;
  cursor: pointer;
}
.Logo--mobile {
  width: calc(190px / 1.5);
  height: calc(43.3px / 1.5);
}

.BurgerMenu {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 8px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;

  width: 40px;
  height: 40px;
  border-radius: calc(48px / 2);
  transition: background-color 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s;

  z-index: 5000;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.BurgerMenu:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.Navigator {
  display: flex;
  flex-flow: column-reverse nowrap;
  justify-content: center;
  align-items: flex-end;
}
.Navigator--mobile {
  background-color: #fff;
  width: 100%;
  margin: 0 -24px;
  padding: 0 8px 8px;
  z-index: 500;
  flex-flow: row-reverse nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1 1;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px;
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
  font-size: 16px;
  /*text-transform: uppercase;*/
}
.menu a {
  font-weight: 500;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 24px;
  transition: color 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s,
    background-color 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  outline: none;
}
.menu a:hover {
  color: rgba(31, 130, 228, 1);
  background-color: rgba(31, 130, 228, 0.1);
  outline: none;
}
.menu a:active {
  outline: none;
  color: rgba(31, 130, 228, 1);
  background-color: rgba(31, 130, 228, 0.2);
}
.menu a.active {
  color: #1f82e4;
}

.languages {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
  font-size: 12px;
}
.languages a {
  font-weight: 400;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  padding: 8px 12px;
  cursor: pointer;
  transition: color 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  outline: none;
}
.languages a:hover {
  color: #1f82e4;
  outline: none;
}
.languages a:active {
  color: gba(31, 130, 228, 0.2);
}
.languages a.active {
  font-weight: bold;
  color: #1f82e4;
}

.screenshot {
  height: 80%;
  width: 30%;
  margin: 0 -8px -36px 0;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: #fbfbfb;
  transition: box-shadow 140ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s,
    -webkit-transform 140ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: transform 140ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s,
    box-shadow 140ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: transform 140ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s,
    box-shadow 140ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s,
    -webkit-transform 140ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  will-change: box-shadow;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.screenshot:hover {
  -webkit-transform: translateY(-16px);
          transform: translateY(-16px);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  z-index: 8000;
}
.screenshotUpper {
  height: 100%;
  width: 33%;
  z-index: 5000;
  margin-bottom: -20px;
}
.screenshotImage {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.feature {
  flex: 1 1;
  padding: 0 24px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}

.feature--icon {
  margin: 20px 0 16px;
  width: 48px;
  height: 48px;
}

.feature--name {
  font-size: 22px;
  font-weight: lighter;
  color: rgba(8, 10, 32, 0.87);
  margin-bottom: 12px;
  line-height: 1.8em;
}

.feature--description {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.67);
  line-height: 1.5em;
  text-align: center;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  height: 100%;
  max-width: 1300px;
  width: 100%;
  text-align: center;
  padding: 0;
  overflow: hidden;
}
.container__full-height {
  height: 100vh;
}

.wrapper {
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  flex-flow: column;
  flex: 1 1;
  width: 100%;
}

.content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  flex: 1 1;
}
.content a {
  font-weight: 500;
  text-decoration: none;
  color: #1f82e4;
  cursor: pointer;
  transition: color 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  outline: none;
}
.content a:hover {
  color: rgb(22, 99, 194);
  outline: none;
  text-decoration: underline;
}
.content a:active {
  outline: none;
  color: rgba(31, 130, 228, 1);
}

.content__full-height {
  flex: 1 1;
  height: 100%;
  margin-bottom: 180px;
}
.Heading {
  font-size: 42px;
  font-family: "Pridi", serif;
  font-weight: bold;
  margin: 16px 0 0 0;
  text-align: center;
  letter-spacing: 1.5px;
  line-height: 46px;
  color: #1b70c5;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.Tagline {
  font-size: 18px;
  font-weight: lighter;
  color: rgba(0, 0, 0, 0.67);
  max-width: 700px;
  line-height: 26px;
  margin-bottom: 24px;
}
.Heading.Heading--mobile {
  margin: 0;
  font-size: 32px;
}
.Tagline.Tagline--mobile {
  font-size: 16px;
}

.Tagline.Tagline--mobile,
.Heading.Heading--mobile {
  padding: 0;
  width: 100%;
  max-width: 100%;
}
.buttonsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  margin: 16px 0 24px;
}

.config-issues {
  font-weight: normal;
  font-style: italic;
  font-size: 12px;
  white-space: pre-line;
  line-height: 1.5em;
}

.Footer__container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 30px !important;
  padding: 16px 24px 12px 24px;
  background-color: #ffffff;
  margin: 0 -24px;
  border-top: 2px solid #e7e7e7;
  box-sizing: border-box;
  font-size: 10px;
}
.Footer__container a {
  font-weight: 500;
  text-decoration: none;
  color: #1f82e4;
  padding: 4px 8px;
  margin: 0px 0 0px 8px;
  cursor: pointer;
  border-radius: 24px;
  transition: color 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s,
    background-color 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  outline: none;
}
.Footer__container a:hover {
  color: rgba(31, 130, 228, 1);
  background-color: rgba(31, 130, 228, 0.1);
  outline: none;
}
.Footer__container a:active {
  outline: none;
  color: rgba(31, 130, 228, 1);
  background-color: rgba(31, 130, 228, 0.2);
}
.Footer__container a.active {
  color: #1f82e4;
}

.Landing__hero {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  margin: 32px 0;
}

.Features__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: row wrap;

  max-width: 1160px;

  padding: 0;
  margin: 32px -60px 32px;
}
.Features__container.Features__container--mobile {
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;

  max-width: 100%;
  width: 100%;
  margin: 0 24px 24px;
}

.Screenshots__container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  align-self: center;
  flex: 1 1;

  max-height: 400px;
  min-height: 200px;
  height: 400px;
  width: 100%;
  max-height: 380px;
  max-width: 960px;

  padding: 0;
  margin: 32px 0 0 0;
  overflow: hidden;
  border-radius: 1px;
}
.Screenshots__container--mobile {
  height: 225px;
}

.content {
  width: 100%;
  margin-bottom: 0;
  padding: 0 24px;
}

.Documentation__container * {
  box-sizing: border-box;
}
.Documentation__container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 1;

  width: 100%;
  height: 100%;

  margin-bottom: 24px;
}

.Documentation__container--mobile {
  flex-flow: column nowrap;
  z-index: 9000;
}

.Documentation__container--mobile > .Menu__container {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;

  overflow-y: auto;
  flex-flow: column nowrap;

  max-width: 300px;
  min-width: 240px;
  width: 70%;
  padding: 16px 0 16px 24px;
  align-items: flex-end;

  transition: opacity 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s,
    -webkit-transform 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s;

  transition: transform 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s,
    opacity 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s;

  transition: transform 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s,
    opacity 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s,
    -webkit-transform 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  -webkit-transform: translateX(-300px);
          transform: translateX(-300px);
}
.Documentation__container--mobile > .Menu__container.Menu__container--shown {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  pointer-events: fill;
  opacity: 1;
}

.Scrim {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: rgba(0, 0, 0, 0.57);
  transition: opacity 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  opacity: 0;
  pointer-events: none;
}
.Scrim--shown {
  cursor: pointer;
  pointer-events: fill;
  overflow: hidden;
  opacity: 1;
}

.Menu__container {
  display: flex;
  flex-flow: column wrap;
  flex: 0 0 245px;
  justify-content: flex-start;
  align-items: flex-start;

  height: 100%;

  padding: 24px 0 24px 0;
}

.Menu__container .Menu__category {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  min-height: 36px;
  width: 100%;

  margin: 0;

  color: rgba(0, 0, 0, 0.47);
  font-weight: bold;
}

.Menu__container .Menu__page {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  min-height: 36px;
  height: 36px;
  width: 100%;

  margin: 0;
  padding: 8px 16px;

  border-bottom-left-radius: calc(36px / 2);
  border-top-left-radius: calc(36px / 2);
  border-right: 4px solid transparent;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  font-size: 14px;
  text-align: left;
  text-decoration: none;
  transition: color 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s,
    background-color 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s,
    border-color 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  outline: none;
}
.Menu__container .Menu__page:hover {
  background-color: rgba(31, 130, 228, 0.1);
  color: rgba(31, 130, 228, 1);
  outline: none;
  text-decoration: none;
}
.Menu__container .Menu__page:focus {
  outline: "none";
}

.Menu__container .Menu__page.Menu__page--active {
  border-right: 4px solid rgba(31, 130, 228, 1);
  color: rgba(31, 130, 228, 1);
  font-weight: bold;
}

.Content__container,
.Content__container--empty {
  flex: 1 1;
  height: 100%;
  padding: 24px;
  height: 100%;

  background-color: white;
  text-align: left;
}

.Content__container {
  height: 100%;
  max-width: 100%;

  margin-bottom: 24px;

  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px;
  border-radius: 2px;
}
.Content__container--empty {
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  width: 100%;

  background-color: transparent;
}

.Content__container p {
  line-height: 26px;
}
.Content__container img {
  max-width: 100% !important;
  height: auto !important;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px;
  border-radius: 2px;
  margin: 0 auto;
}
.Content__container .block-img {
  max-width: 100% !important;
  height: auto !important;
  margin: 36px 0;
  text-align: center;
}

.Content__container *:first-child {
  margin-top: 0 !important;
}
.Content__container *:last-child {
  margin-bottom: 0 !important;
}

.Content__container > h1 {
  font-size: 24px;
}

.Content__container > div {
  font-size: 16px;
}
.Content__container > div > h5 {
  font-size: 14px;
}
.Content__container > div > h5 > em,
.Content__container > div > h6 > em {
  font-weight: normal;
}

.Content__title {
  padding: 0 24px 16px;
  margin: 0 -24px 16px -24px;

  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

pre {
  overflow: auto;
  padding: 16px;
  max-width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #fcfcfc;
}

ul {
  margin: 16px 12px;
  padding: 0 12px;
}

ul > li {
  margin-bottom: 8px;
}

ul > li:last-child {
  margin-bottom: 0;
}

.Contact__container {
  text-align: left;
}

.Contact__container {
  text-align: left;
}

h1 > h6 {
  margin: 0;
  font-weight: normal;
  font-size: 11px;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #f2f2f2;
  -webkit-font-smoothing: antialiased;
  overflow-y: scroll;
}

#root {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

h1 {
  font-family: "Pridi", serif;
}

h2,
h3 {
  font-family: "Open Sans", sans-serif;
}

.logo,
button,
a {
  -webkit-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}

img {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.container {
  min-height: 100vh;
  width: 100%;

  background-color: #fafafa;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px;
}

.spinner {
  -webkit-animation: rotation 1.35s linear infinite;
  animation: rotation 1.35s linear infinite;
}

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

.circle {
  stroke-dasharray: 180;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: turn 1.35s ease-in-out infinite;
  animation: turn 1.35s ease-in-out infinite;
}

@-webkit-keyframes turn {
  0% {
    stroke-dashoffset: 180;
  }

  50% {
    stroke-dashoffset: 45;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 180;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

@keyframes turn {
  0% {
    stroke-dashoffset: 180;
  }

  50% {
    stroke-dashoffset: 45;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 180;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

