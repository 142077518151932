.Header {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  min-height: 56px;

  padding: 16px 24px;
  background-color: #ffffff;
  margin: 0 -24px 24px -24px;
  border-bottom: 2px solid #e7e7e7;
}
.Header--mobile {
  align-items: center;
  border-bottom: none;
  padding: 0;
  flex-direction: row-reverse;
}

.mobileMenuWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.logo {
  flex: 0.2;
  justify-self: flex-start;
  cursor: pointer;
}

.Logo__wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.Logo__wrapper--mobile {
  height: 50px;
  width: 100%;
  margin: 0;
  padding: 8px 8px;
  background-color: #fff;
  justify-content: space-between;
  z-index: 2000;
}

.Logo {
  width: 190px;
  height: 43.3px;
  cursor: pointer;
}
.Logo--mobile {
  width: calc(190px / 1.5);
  height: calc(43.3px / 1.5);
}

.BurgerMenu {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 8px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;

  width: 40px;
  height: 40px;
  border-radius: calc(48px / 2);
  transition: background-color 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s;

  z-index: 5000;
  user-select: none;
}
.BurgerMenu:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.Navigator {
  display: flex;
  flex-flow: column-reverse nowrap;
  justify-content: center;
  align-items: flex-end;
}
.Navigator--mobile {
  background-color: #fff;
  width: 100%;
  margin: 0 -24px;
  padding: 0 8px 8px;
  z-index: 500;
  flex-flow: row-reverse nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px, rgba(0, 0, 0, 0.2) 0px 3px 1px -2px;
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
  font-size: 16px;
  /*text-transform: uppercase;*/
}
.menu a {
  font-weight: 500;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 24px;
  transition: color 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s,
    background-color 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  outline: none;
}
.menu a:hover {
  color: rgba(31, 130, 228, 1);
  background-color: rgba(31, 130, 228, 0.1);
  outline: none;
}
.menu a:active {
  outline: none;
  color: rgba(31, 130, 228, 1);
  background-color: rgba(31, 130, 228, 0.2);
}
.menu a.active {
  color: #1f82e4;
}

.languages {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
  font-size: 12px;
}
.languages a {
  font-weight: 400;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  padding: 8px 12px;
  cursor: pointer;
  transition: color 280ms cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  outline: none;
}
.languages a:hover {
  color: #1f82e4;
  outline: none;
}
.languages a:active {
  color: gba(31, 130, 228, 0.2);
}
.languages a.active {
  font-weight: bold;
  color: #1f82e4;
}
